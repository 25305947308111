var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('core-section',{attrs:{"id":"lets-talk"}},[_c('core-heading',[_vm._v("Let's talk.")]),_c('v-col',{staticClass:"mb-4 pr-md-5",attrs:{"cols":"12","md":"5"}},[_c('core-text',{staticClass:"mb-5"},[_vm._v(" "+_vm._s(_vm.schema.basics.forYou)+" ")]),_c('core-subheading',{staticClass:"text-uppercase mb-3"},[_vm._v(" Send me a Message ")]),_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-form',{ref:"contactForm"},[_c('validation-provider',{attrs:{"name":"Name","rules":"required|max:100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"filled":"","label":"Name","required":""},model:{value:(_vm.contactDetails['name']),callback:function ($$v) {_vm.$set(_vm.contactDetails, 'name', $$v)},expression:"contactDetails['name']"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"Email","rules":"required|email|max:100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"filled":"","label":"Email","required":""},model:{value:(_vm.contactDetails['email']),callback:function ($$v) {_vm.$set(_vm.contactDetails, 'email', $$v)},expression:"contactDetails['email']"}})]}}],null,true)}),_c('validation-provider',{attrs:{"rules":"required|max:100","name":"Subject"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"filled":"","label":"Subject","required":""},model:{value:(_vm.contactDetails['subject']),callback:function ($$v) {_vm.$set(_vm.contactDetails, 'subject', $$v)},expression:"contactDetails['subject']"}})]}}],null,true)}),_c('validation-provider',{attrs:{"rules":"required|max:500","name":"Message"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-textarea',{attrs:{"error-messages":errors,"counter":500,"filled":"","label":"Message","required":""},model:{value:(_vm.contactDetails['message']),callback:function ($$v) {_vm.$set(_vm.contactDetails, 'message', $$v)},expression:"contactDetails['message']"}})]}}],null,true)}),_c('div',{staticClass:"pb-4 mb-2"},[_c('vue-recaptcha',{ref:"recaptcha",attrs:{"sitekey":_vm.recaptchaSiteKey,"load-recaptcha-script":true},on:{"verify":_vm.onVerify}})],1),_c('v-btn',{staticClass:"ma-0",attrs:{"color":"primary","disabled":invalid},on:{"click":_vm.submit}},[_vm._v(" Contact Me ")])],1)]}}])})],1),_c('v-col',{staticClass:"text-left",attrs:{"cols":"12","md":"5"}},[_c('core-subheading',{staticClass:"text-uppercase"},[_vm._v(" Social Contacts ")]),_c('social-contacts'),_c('v-list',{staticClass:"transparent",attrs:{"three-line":""}},[_c('v-list-item',[_c('v-list-item-icon',[_c('v-icon',{staticClass:"mr-5",attrs:{"size":"48","color":"primary"}},[_vm._v(" mdi-email ")])],1),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"primary--text"},[_vm._v(" Email ")]),_c('v-list-item-subtitle',{domProps:{"textContent":_vm._s(_vm.schema.basics.email)}})],1)],1)],1)],1),_c('v-img',{staticStyle:{"bottom":"0px","right":"0","position":"absolute"},attrs:{"src":require('@/assets/josh-alt.png'),"contain":"","max-width":"30vw","width":"100%"}}),_c('v-snackbar',{scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"color":"pink","text":""},on:{"click":function($event){_vm.snackbar = false}}},'v-btn',attrs,false),[_vm._v(" Close ")])]}}]),model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.text)+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }